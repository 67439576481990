<template>
    <div>
        <h2>จัดการคลัง/สาขา</h2>
        <v-card class="mt-4">
          <div class="d-flex pa-4">     
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'warehouse_create', params: { warehouse_id: 'create' } })">เพิ่มคลัง/สาขา</v-btn>
          </div>
          <delay-datatable
            :headers="datatable.headers"
            :url="'/inventory/warehouse/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template          
            v-slot:item.address="{ item }"
          >
            <div>
              {{ item.address }} {{ item.city }} {{ item.county }} {{ item.province }} {{ item.postcode }} 
            </div>
            <div>
              {{ item.tel }}
            </div>            
          </template>

          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'warehouse_create', params: { warehouse_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'Inventory') ? mdiPlaylistEdit : mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.name)"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'Inventory') || item.products_count > 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>        
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'ชื่อคลัง/สาขา',
            value: 'name',
          },
          {
            text: 'ที่อยู่',
            value: 'address',
          },
          {
            text: 'หมายเหตุ',
            value: 'note',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiDeleteOutline,
      mdiPlaylistEdit,
      mdiEyeOutline,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/inventory/warehouse', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      /*
      try {
        const result = await asyncDelete('/inventory/warehouse', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      */
    },
  },
}
</script>